import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { PMField } from "../../../utils/PMField";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { axiosWithToast, mapErrors } from "../../../Utils";
import PMSwitch from "../../../utils/PMSwitch";
import {
  cancelReportAgreement,
  fetchReport,
  newReportAgreement,
  resendReportAppointment,
  sendAgreementConfirmationReminder,
  updateReportAgreement,
} from "../../../../api/service/report";
import { ResourceLoading } from "../../../utils/ResourceLoading";
import { ResourceError } from "../../../utils/ResourceError";
import { ResourceEmpty } from "../../../utils/ResourceEmpty";
import PMListboxFormik from "../../../utils/PMListboxFormik";
import { cancelReasons, confirmationTypes } from "./report-data";
import { fetchUsers } from "../../../../api/users";
import PMCombobox from "../../../utils/PMCombobox";
import { ReportAgreementVerifier2 } from "./utils";
import { PMNotice } from "../../../utils/notifications";
import { Prompt } from "../../../utils/Prompt";
import SimplePrompt from "../../../utils/simplePrompt";

export default function ReportAgreementModal(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    report: {},
  });

  const { open, onClose, selected, onRefresh } = props;

  const { report, isLoading, error, errors } = itemState;

  const getReport = () => {
    const params = {
      fields:
        "id,customer.id,customer.mobile,customer.email,appointment,executing_employee,operations",
    };
    return fetchReport(selected.id, params)
      .then((res) => {
        if (res.data.id) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            report: res.data,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err.data.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          report: {},
          error: true,
          errors: errors,
        }));
      });
  };

  const postReport = (action, payload) => {
    return reportPostAction(action, payload)
      .then((res) => {
        if (res.data.id) {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            report: res.data,
          }));
        }
        props.onRefresh();
        return true;
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err.data.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          report: {},
          error: true,
          errors: errors,
        }));
      });
  };

  const reportPostAction = (action, payload) => {
    if (action === "new") {
      return newReportAgreement(payload);
    }
    if (action === "update") {
      return updateReportAgreement(payload);
    }
    if (action === "cancel") {
      return cancelReportAgreement(payload);
    }
  };

  useEffect(() => {
    if (selected?.id) {
      setItemState((prevState) => ({
        ...prevState,
        isLoading: true,
        report: {},
        error: false,
        errors: {},
      }));
      if (open === true) {
        getReport();
      }
    }
  }, [dispatch, open]);

  const [userOptions, setUserOptions] = useState({
    isLoading: true,
    error: false,
    errors: [],
    data: [],
  });

  async function fetchAndMapUsers() {
    setUserOptions((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    const controller = {
      filters: [{ name: "role", value: "service" }],
      page: 1,
      query: "",
      sort: "desc",
      sortBy: "id",
      view: "all",
    };

    await fetchUsers(controller)
      .then((res) => {
        if (res.data) {
          var array = [];
          if (!res.data) return array;
          res.data.map((row, i) => {
            const rowParams = {
              value: row.id,
              title: row.name,
              order: i,
            };
            array.push(rowParams);
          });
          setUserOptions((prevState) => ({
            ...prevState,
            isLoading: false,
            data: array,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        setUserOptions((prevState) => ({
          ...prevState,
          isLoading: false,
          error: true,
          errors: errors,
        }));
      });

    // Map into an array with ID and title?

    // Return the array
  }

  useEffect(() => {
    if (open) {
      fetchAndMapUsers();
    }
  }, [open]);

  const [sendingReminder, setSendingReminder] = useState(false);
  const [sendingReminderPrompt, setSendingReminderPrompt] = useState(false);
  const [resendingAppointment, setResendingAppointment] = useState(false);
  const [resendAppointmentPrompt, setResendAppointmentPrompt] = useState(false);

  async function agreementConfirmationReminder(report) {
    // send reminder

    setSendingReminder(true);

    const params = {
      id: report.id,
      bySms: true,
    };

    await sendAgreementConfirmationReminder(params)
      .then((res) => {
        setSendingReminder(false);
        if (res.status === 204) {
          toast.success("Reminder sent successfully");
        } else {
          toast.error("Reminder could not be sent");
        }
      })
      .catch((err) => {
        setSendingReminder(false);
        toast.error("Reminder could not be sent");
      });
  }

  async function resendAppointmentToCustomer(report) {
    // send reminder

    setResendingAppointment(true);

    const params = {
      id: report.id,
    };

    await resendReportAppointment(params)
      .then((res) => {
        setResendingAppointment(false);
        if (res.status === 200) {
          toast.success("Resent appointment successfully");
        } else {
          toast.error("Text Message could not be resent");
        }
      })
      .catch((err) => {
        setResendingAppointment(false);
        toast.error("Text Message could not be resent");
      });
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full sm:max-w-2xl max-w-md transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    {report?.appointment?.has_agreement
                      ? t("report.agreement.update")
                      : t("report.agreement.new")}
                  </Dialog.Title>

                  {isLoading ? (
                    <ResourceLoading />
                  ) : error ? (
                    <ResourceError errors={errors} />
                  ) : !report.id ? (
                    <ResourceEmpty />
                  ) : (
                    <ReportAgreementVerifier2 report={report}>
                      <Formik
                        initialValues={{
                          id: report?.id,
                          has_agreement: report?.appointment?.has_agreement,
                          executing_employee: report?.executing_employee?.id,
                          agreement_time_too: report?.appointment?.time_too,
                          agreement_time_from: report?.appointment?.time_from,
                          agreement_time: report?.appointment?.time,
                          agreement_date: report?.appointment?.date,
                          to_phone_number: report?.customer?.mobile,
                          to_email_address: report?.customer?.email,
                          fixedTime: report?.appointment?.time ? true : false,
                          manualConfirmationType: null,
                          cancelReason: null,
                          sendSmsToCustomer: report?.customer?.mobile
                            ? true
                            : false,
                          sendEmailToCustomer: report?.customer?.email
                            ? true
                            : false,
                          appointmentConfirmationRequired: true,
                          submit: null,
                        }}
                        validationSchema={Yup.object({
                          // timeframe is required if fixedTime is false

                          // required only if report?.appointment?.has_agreement is false

                          has_agreement: Yup.boolean().required(),
                          fixedTime: Yup.boolean().required(),
                          sendSmsToCustomer: Yup.boolean().required(),
                          sendEmailToCustomer: Yup.boolean().required(),

                          agreement_time_from: Yup.string()
                            .nullable()
                            .when(["has_agreement", "fixedTime"], {
                              is: (has_agreement, fixedTime) =>
                                !has_agreement && !fixedTime,
                              then: Yup.string()
                                .nullable()
                                .required("Tidsrom fra er påkrevd"),
                              otherwise: Yup.string().nullable().notRequired(),
                            }),

                          agreement_time_too: Yup.string()
                            .nullable()
                            .when(["has_agreement", "fixedTime"], {
                              is: (has_agreement, fixedTime) =>
                                !has_agreement && !fixedTime,
                              then: Yup.string()
                                .nullable()
                                .required("Tidsrom til er påkrevd"),
                              otherwise: Yup.string().nullable().notRequired(),
                            }),

                          agreement_time: Yup.string()
                            .nullable()
                            .when(["has_agreement", "fixedTime"], {
                              is: (has_agreement, fixedTime) =>
                                !has_agreement && fixedTime,
                              then: Yup.string()
                                .nullable()
                                .required("Tidspunkt er påkrevd"),
                              otherwise: Yup.string().nullable().notRequired(),
                            }),

                          agreement_date:
                            Yup.date().required("Dato er påkrevd"),

                          executing_employee: Yup.string().required(
                            "Utførende er påkrevd"
                          ),

                          to_phone_number: Yup.string().when(
                            "sendSmsToCustomer",
                            {
                              is: true,
                              then: Yup.string().required(
                                "Mobilnummer er påkrevd for å sende SMS til kunden"
                              ),
                              otherwise: Yup.string().notRequired(),
                            }
                          ),

                          to_email_address: Yup.string().when(
                            "sendEmailToCustomer",
                            {
                              is: true,
                              then: Yup.string().required(
                                "E-post er påkrevd for å sende e-post til kunden"
                              ),
                              otherwise: Yup.string().notRequired(),
                            }
                          ),

                          customer: Yup.string(),
                        })}
                        onSubmit={(values, helpers) => {
                          try {
                            axiosWithToast(
                              reportPostAction(
                                report?.appointment?.has_agreement
                                  ? values.cancelReason !== null
                                    ? "cancel"
                                    : "update"
                                  : "new",
                                values
                              ),
                              () => {
                                props.onRefresh();
                              }, // On Refresh
                              () => {}, // On close
                              (data) => {
                                console.log(data);
                                setItemState((prevState) => ({
                                  ...prevState,
                                  isLoading: false,
                                  report: data,
                                }));
                              } // Set data
                            );

                            helpers.setStatus({ success: true });
                            helpers.setSubmitting(false);
                          } catch (err) {
                            toast.error("Something went wrong...");
                            console.error(err);
                            helpers.setStatus({ success: false });
                            helpers.setErrors({ submit: err.message });
                            helpers.setSubmitting(false);
                          }
                        }}
                      >
                        {(formik) => {
                          console.log(formik);
                          return (
                            <Form>
                              {report?.case?.id && (
                                <PMNotice
                                  title={
                                    "Rapport er knyttet til SAK-" +
                                    report?.case?.case_number
                                  }
                                  description={
                                    "Oppdatering her vil føres inn i saken automatisk"
                                  }
                                  type={"info"}
                                />
                              )}

                              <div className="grid grid-cols-6 gap-6 mt-10">
                                {report?.appointment?.has_agreement ? (
                                  <>
                                    {!report?.appointment?.approved ? (
                                      <>
                                        <div className="col-span-6 sm:col-span-6">
                                          <button
                                            type="button"
                                            className="bg-blue-400 rounded-md shadow-sm px-4 py-2 text-white disabled:bg-gray-400 disabled:cursor-not-allowed"
                                            disabled={sendingReminder}
                                            onClick={() =>
                                              setSendingReminderPrompt(true)
                                            }
                                          >
                                            {t("Send confirmation reminder")}
                                          </button>
                                          <SimplePrompt
                                            title="Er du sikker på at du vill sende en påminnelse?"
                                            description="Sørg for at du ikke allerede har sendt en påminnelse."
                                            onAccept={() =>
                                              agreementConfirmationReminder(
                                                report
                                              )
                                            }
                                            open={sendingReminderPrompt}
                                            onClose={() =>
                                              setSendingReminderPrompt(false)
                                            }
                                          />

                                          {report?.appointment
                                            ?.agreement_method ===
                                            "TextMessage" && (
                                            <>
                                              <button
                                                type="button"
                                                className="ml-2 bg-blue-400 rounded-md shadow-sm px-4 py-2 text-white disabled:bg-gray-400 disabled:cursor-not-allowed"
                                                disabled={resendingAppointment}
                                                onClick={() =>
                                                  setResendAppointmentPrompt(
                                                    true
                                                  )
                                                }
                                              >
                                                {t("Send avtale på nytt")}
                                              </button>
                                              <SimplePrompt
                                                title="Er du sikker på at du vill sende avtalen på nytt?"
                                                description="Sørg for at du ikke allerede har sendt ny melding?"
                                                onAccept={() =>
                                                  resendAppointmentToCustomer(
                                                    report
                                                  )
                                                }
                                                open={resendAppointmentPrompt}
                                                onClose={() =>
                                                  setResendAppointmentPrompt(
                                                    false
                                                  )
                                                }
                                              />
                                            </>
                                          )}
                                        </div>
                                        <div className="col-span-6 sm:col-span-6 mb-20">
                                          <PMListboxFormik
                                            label={t(
                                              "Manually confirm agreement"
                                            )}
                                            name="manualConfirmationType"
                                            options={confirmationTypes}
                                            {...formik}
                                          />
                                        </div>{" "}
                                      </>
                                    ) : null}
                                    <div className="col-span-6 sm:col-span-6">
                                      <PMListboxFormik
                                        label={t("cancel.reason")}
                                        name="cancelReason"
                                        options={cancelReasons}
                                        {...formik}
                                      />
                                    </div>

                                    {formik.values.cancelReason !== null && (
                                      <div className="col-span-6 sm:col-span-6">
                                        <PMSwitch
                                          name="sendSmsToCustomer"
                                          label={t("send-sms.customer")}
                                          {...formik}
                                        />
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <div className="col-span-6 sm:col-span-6">
                                      <PMCombobox
                                        name="executing_employee"
                                        label={t("Executing Employee")}
                                        options={userOptions.data}
                                        {...formik}
                                      />
                                    </div>
                                    <div className="col-span-6 sm:col-span-6">
                                      <PMField
                                        name="agreement_date"
                                        type="date"
                                        label={t("Agreement date")}
                                        {...formik}
                                      />
                                    </div>

                                    <div className="col-span-6 sm:col-span-6 mt-5">
                                      <PMSwitch
                                        name="fixedTime"
                                        type="time"
                                        label={t("Fixed time")}
                                        {...formik}
                                      />
                                    </div>

                                    {formik.values.fixedTime ? (
                                      <>
                                        <div className="col-span-6 sm:col-span-6">
                                          <span className="text-gray-400 italic">
                                            Nå velger du spesefikk tid for
                                            avtalen. Det betyr at oppmøte skjer
                                            ved valgt tid
                                          </span>
                                        </div>
                                        <div className="col-span-6 sm:col-span-6">
                                          <PMField
                                            name="agreement_time"
                                            type="time"
                                            label={t("Fixed time")}
                                            {...formik}
                                          />
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        <div className="col-span-6 sm:col-span-6">
                                          <span className="text-gray-400 italic">
                                            Nå velger du tidsrom for avtalen.
                                            Det betyr at oppmøte skjer innenfor
                                            valgt tidsrom
                                          </span>
                                        </div>
                                        <div className="col-span-6 sm:col-span-6">
                                          <PMField
                                            name="agreement_time_from"
                                            type="time"
                                            label={t("Timeframe from")}
                                            {...formik}
                                          />
                                        </div>

                                        <div className="col-span-6 sm:col-span-6">
                                          <PMField
                                            name="agreement_time_too"
                                            type="time"
                                            label={t("Timeframe to")}
                                            {...formik}
                                          />
                                        </div>
                                      </>
                                    )}

                                    <div className="col-span-6 sm:col-span-6">
                                      <div className="p-2 mt-5">
                                        <div className="mt-2">
                                          <PMSwitch
                                            name="appointmentConfirmationRequired"
                                            type="time"
                                            label={t(
                                              "Appointment confirmation required"
                                            )}
                                            {...formik}
                                          />
                                        </div>

                                        {formik.values
                                          .appointmentConfirmationRequired ? (
                                          <div className="col-span-6 sm:col-span-6 mt-2">
                                            <span className="text-gray-400 italic">
                                              Avtale sendes til kunde, som må
                                              bekreftes via SMS eller e-post
                                            </span>
                                          </div>
                                        ) : (
                                          <div className="col-span-6 sm:col-span-6 mt-2">
                                            <span className="text-gray-400 italic">
                                              Informasjon om avtale sendes
                                              kunde, men bekreftelse er ikke
                                              påkrevd. Avtalen blir bekreftet
                                              automatisk.
                                            </span>
                                          </div>
                                        )}

                                        <div className="mt-10 flex flex-row">
                                          <div>
                                            <PMSwitch
                                              name="sendSmsToCustomer"
                                              type="time"
                                              label={t("send-sms.customer")}
                                              {...formik}
                                            />
                                          </div>
                                          <div className="ml-5">
                                            <PMSwitch
                                              name="sendEmailToCustomer"
                                              type="time"
                                              label={t(
                                                "Send email to customer"
                                              )}
                                              {...formik}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {formik.values.sendSmsToCustomer ? (
                                      <div className="col-span-6 sm:col-span-6">
                                        <PMField
                                          name="to_phone_number"
                                          type="text"
                                          label={t("Phone number")}
                                          {...formik}
                                        />
                                      </div>
                                    ) : null}

                                    {formik?.values?.sendEmailToCustomer ? (
                                      <div className="col-span-6 sm:col-span-6">
                                        <PMField
                                          name="to_email_address"
                                          type="text"
                                          label={t("Email address")}
                                          {...formik}
                                        />
                                      </div>
                                    ) : null}
                                  </>
                                )}
                              </div>

                              <div className="">
                                <div className="flex justify-between mt-20">
                                  <button
                                    type="button"
                                    className="w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                    onClick={onClose}
                                  >
                                    {t("Close")}{" "}
                                  </button>

                                  <button
                                    type="button"
                                    className="rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                                    onClick={formik.submitForm}
                                  >
                                    {report?.id
                                      ? formik.values.cancelReason
                                        ? t("Cancel")
                                        : t("Update")
                                      : t("Create")}{" "}
                                  </button>
                                </div>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>
                    </ReportAgreementVerifier2>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

ReportAgreementModal.defaultProps = {
  onRefresh: null,
};
