import {
  CheckBadgeIcon,
  ExclamationTriangleIcon,
  PencilIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import ReportAgreementModal from "./report-agreement-modal";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ReportAppointmentApprovedStatus } from "./utils";
import SMSDialog from "../../sms/dialog";
import { CopyToClipboard } from "../../../utils/Div";
export const ReportAgreement = (props) => {
  const { report } = props;

  const [agreementModalOpen, setAgreementModalOpen] = useState(false);

  const [smsDialogOpen, setSMSDialogOpen] = useState(false);

  const { t } = useTranslation();

  const attachments = [
    { name: "resume_front_end_developer.pdf", href: "#" },
    { name: "coverletter_front_end_developer.pdf", href: "#" },
  ];

  return (
    <>
      {/* Description list*/}
      <section
        className="lg:max-w[7xl] h-full"
        aria-labelledby="applicant-information-title"
      >
        <div className="bg-white shadow sm:rounded-lg h-full">
          <div className="flex items-center justify-between px-4 py-5 sm:px-6 w-full">
            <div className="">
              <h2
                id="applicant-information-title"
                className="text-lg font-medium leading-6 text-gray-900"
              >
                Avtale
              </h2>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                Informasjon om rapportens avtale
              </p>
            </div>
            <div className="flex">
              <div>
                <button
                  onClick={() => setSMSDialogOpen(true)}
                  type="button"
                  className="inline-flex items-center rounded-md border border-transparent bg-blue-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <PhoneIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  SMS
                </button>
              </div>
              <div className="ml-2">
                <button
                  onClick={() => setAgreementModalOpen(true)}
                  type="button"
                  className="inline-flex items-center rounded-md border border-transparent bg-blue-500 px-3 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  <PencilIcon
                    className="-ml-0.5 mr-2 h-4 w-4"
                    aria-hidden="true"
                  />
                  Avtale
                </button>
              </div>
            </div>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Utføres av
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {report?.executing_employee
                    ? report?.executing_employee?.name
                    : t("not_chosen")}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Dato</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {report?.appointment?.date ? (
                    <CopyToClipboard
                      path={null}
                      content={report?.appointment?.date}
                      type="popup"
                    />
                  ) : (
                    t("not_chosen")
                  )}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  {report?.appointment?.time ? "Tidspunkt" : "Tidsrom"}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {report?.appointment?.time ? (
                    report?.appointment?.time
                  ) : report?.appointment?.time_from &&
                    report?.appointment?.time_too ? (
                    <CopyToClipboard
                      path={null}
                      content={
                        report?.appointment?.time
                          ? report?.appointment?.time
                          : report?.appointment?.time_from +
                            " - " +
                            report?.appointment?.time_too
                      }
                      type="popup"
                    />
                  ) : (
                    t("not_chosen")
                  )}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Status</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <ReportAppointmentApprovedStatus report={report} />

                  {report?.appointment?.has_agreement ? (
                    <>
                      {report?.appointment?.approved ? (
                        <div className="flex items-center space-x-2">
                          <CheckBadgeIcon className="inline w-5 h-5 text-green-700" />
                          <span>Avtalen er bekreftet</span>
                        </div>
                      ) : (
                        <div className="flex items-center space-x-2">
                          <ExclamationTriangleIcon className="inline w-5 h-5 text-red-700" />
                          <span>Avtalen er ikke bekreftet</span>
                        </div>
                      )}

                      {report?.appointment?.text_sent ? (
                        <>
                          <div className="flex items-center space-x-2">
                            <CheckBadgeIcon className="inline w-5 h-5 text-green-700" />
                            <span>Melding er sendt</span>
                          </div>
                          {report?.appointment?.approved_via_text ? (
                            <div className="flex items-center space-x-2">
                              <CheckBadgeIcon className="inline w-5 h-5 text-green-700" />
                              <span>Melding er bekreftet via SMS</span>
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <div className="flex items-center space-x-2">
                          <ExclamationTriangleIcon className="inline w-5 h-5 text-red-700" />
                          <span>Melding er ikke sendt</span>
                        </div>
                      )}

                      {report?.appointment?.email_sent ? (
                        <>
                          <div className="flex items-center space-x-2">
                            <CheckBadgeIcon className="inline w-5 h-5 text-green-700" />
                            <span>E-post er sendt</span>
                          </div>
                        </>
                      ) : (
                        <div className="flex items-center space-x-2">
                          <ExclamationTriangleIcon className="inline w-5 h-5 text-red-700" />
                          <span>E-post er ikke sendt</span>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="flex items-center space-x-2">
                      <ExclamationTriangleIcon className="inline w-5 h-5 text-yellow-700" />
                      <span>Ingen avtale</span>
                    </div>
                  )}
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </section>
      <ReportAgreementModal
        open={agreementModalOpen}
        onClose={() => setAgreementModalOpen(false)}
        selected={report}
        onRefresh={() => props.onRefresh()}
      />

      <SMSDialog
        open={smsDialogOpen}
        onClose={() => setSMSDialogOpen(false)}
        selected={report}
        onRefresh={() => props.onRefresh()}
        module="report"
        module_param={report?.id}
      />
    </>
  );
};

ReportAgreement.defaultProps = {
  onRefresh: null,
};
