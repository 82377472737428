import { Fragment, useEffect, useState } from "react";
import { Popover, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { PMField } from "../../utils/PMField";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  axiosWithToast,
  ChangedValues,
  ContactTitle,
  CustomerTitle,
  dispatchWithToast,
  GoogleMapsButton,
  mapErrors,
  NewLineFormatter,
  ReportTitle,
  searchFieldsByName,
} from "../../Utils";
import {
  createCaseItem,
  deleteCaseItem,
  fetchCase,
  fetchCaseTypes,
} from "../../../api/case";
import {
  createCaseAsync,
  deleteCaseAsync,
  updateCaseAsync,
} from "../../../slices/case";
import PMCombobox from "../../utils/PMCombobox";
import { CalendarIcon } from "@heroicons/react/20/solid";

import {
  BanknotesIcon,
  BuildingOffice2Icon,
  ChevronDownIcon,
  Cog6ToothIcon,
  PencilSquareIcon,
  TrashIcon,
  UsersIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import PMLinkCard from "../../utils/PMLinkCard";
import { CopyToClipboard, TWclassNames } from "../../utils/Div";
import { DocumentViewer } from "../Media/documentViewer";
import { fetchUsers } from "../../../api/users";
import ServiceReportEditModal from "../service/reports/service-report-edit-modal";
import CustomerDialog from "../customers/dialog";
import { CasePriorityStatus, CaseStatus, caseStatusOptions } from "./utils";
import { CustomerSelector } from "../../utils/moduleSelectors/customerSelector";
import ServiceAgreementModal from "../service/agreements/modal";
import { DocumentUploadSectionV2 } from "../Media/DocumentUploadSectionV2";
import { priorityOptions } from "../quality/deviations/utils";
import SimplePrompt from "../../utils/simplePrompt";
import PMDropdown from "../../utils/PMDropdown";
import { PMDialog } from "../../utils/PMDialog";
import { ContactsSelector } from "../../utils/moduleSelectors/contactSelector";
import { ReportSelector } from "../../utils/moduleSelectors/reportSelector";
import { useSelector } from "react-redux";

export function TagsView({ tags, onDeleteTag }) {
  const tagColor = (tag) => {
    switch (tag?.module) {
      case "report":
        return "bg-blue-100 text-blue-800";
      case "contact":
        return "bg-green-100 text-green-800";
      case "customer":
        return "bg-red-100 text-black";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const TagTitle = (tag) => {
    switch (tag?.module) {
      case "report":
        return <ReportTitle report={tag?.object} />;
      case "contact":
        return <ContactTitle contact={tag?.object} />;
      case "customer":
        return <CustomerTitle customer={tag?.object} useDialog={true} />;
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  return tags?.map((item, idx) => {
    return (
      <span
        key={item?.module + item?.module_param}
        className={TWclassNames(
          tagColor(item),
          "inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium mr-2"
        )}
      >
        <span className="flex justify-between">
          <span>
            {item?.id
              ? item?.object
                ? TagTitle(item)
                : item?.module_param
              : item?.object
              ? item?.object?.displayTitle
              : item?.module + " " + item?.module_param}
          </span>
          {!item?.id && (
            <button
              type="button"
              className="cursor-pointer"
              onClick={onDeleteTag(idx)}
            >
              <XCircleIcon className="h-5 w-5 ml-2" />
            </button>
          )}
        </span>
      </span>
    );
  });
}

TagsView.defaultProps = {
  onDeleteTag: () => {},
};

export function CaseItemUpdate(props) {
  const { caseItem } = props;
  const { t } = useTranslation();

  const createItem = async (values) => {
    return await createCaseItem(values);
  };
  const [documents, setDocuments] = useState([]);

  const UploaderCallback = (data, formik) => {
    // Add documents to document state
    setDocuments([...documents, ...data]);

    // Add documents to formik
    formik.setFieldValue("documents", [...documents, ...data]);

    setIsLoading(false);
  };

  const [isLoading, setIsLoading] = useState(false);

  const [openModuleDialog, setOpenModuleDialog] = useState();

  const moduleAction = (type) => {
    setOpenModuleDialog(null);
    setTimeout(() => {
      setOpenModuleDialog(type);
    }, 25);
  };

  const handleModuleSelectorOnClose = (state) => {
    if (!state) {
      setOpenModuleDialog(null);
    }
  };

  const [tags, setTags] = useState([]);

  function TagSelector({ formik }) {
    const moduleTags = [
      { name: t("Report"), action: () => moduleAction("report") },
      { name: t("Contact"), action: () => moduleAction("contact") },
      { name: t("Customer"), action: () => moduleAction("customer") },
    ];

    const onModuleDialogSelectorChange = (type, e) => {
      console.log("type", type);
      console.log("data", e);

      const payload = {
        module: type,
        module_param: e?.target?.value,
        object: e?.target?.data?.object,
      };

      // add as tags
      // module + id
      setTags([...tags, payload]);

      console.log("tags", tags);

      setOpenModuleDialog(null);

      formik.setFieldValue("tags", [...tags, payload]);

      console.log(formik.values.tags);
    };

    return (
      <>
        <Popover className="relative">
          <Popover.Button className="inline-flex items-center gap-x-1 text-md px-2 leading-6 text-blue-400 hover:text-blue-600">
            <span>{t("Tags")}</span>
            <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
          </Popover.Button>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="opacity-0 translate-y-1"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease-in duration-150"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 translate-y-1"
          >
            <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4">
              <div className="w-56 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                {moduleTags.map((item) => (
                  <a
                    key={item.name}
                    onClick={item.action}
                    className="block p-2 hover:text-blue-400 cursor-pointer"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </Popover.Panel>
          </Transition>
        </Popover>

        <ContactsSelector
          showField={false}
          open={openModuleDialog === "contact"}
          onClose={(state) => handleModuleSelectorOnClose(state)}
          onChange={(data) => onModuleDialogSelectorChange("contact", data)}
        />

        <CustomerSelector
          showField={false}
          open={openModuleDialog === "customer"}
          onClose={(state) => handleModuleSelectorOnClose(state)}
          onChange={(data) => onModuleDialogSelectorChange("customer", data)}
        />

        <ReportSelector
          showField={false}
          open={openModuleDialog === "report"}
          onClose={(state) => handleModuleSelectorOnClose(state)}
          onChange={(data) => onModuleDialogSelectorChange("report", data)}
        />
      </>
    );
  }

  const handleDeleteTag = (idx, formik) => () => {
    const newTags = [...formik.values.tags];
    newTags.splice(idx, 1);
    formik.setFieldValue("tags", newTags);
    setTags(newTags);
  };

  const initialValues = {
    id: null,
    case_id: caseItem.id,
    dueDate: null,
    title: null,
    description: null,
    documents: null,
    submit: null,
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={Yup.object({
        description: Yup.string().required(t("Description is required")),
      })}
      onSubmit={(values, helpers) => {
        try {
          axiosWithToast(createItem(values), props.onChange);
          helpers.setStatus({ success: true });
          helpers.setSubmitting(false);
          helpers.setFieldValue("description", "");
          helpers.resetForm(initialValues);
        } catch (err) {
          toast.error("Something went wrong...");
          console.error(err);
          helpers.setStatus({ success: false });
          helpers.setErrors({ submit: err.message });
          helpers.setSubmitting(false);
        }
      }}
    >
      {(formik) => {
        return (
          <Form className="relative h-full max-h-[200px]">
            <div className="rounded-lg border border-gray-300 shadow-sm focus-within:border-blue-400 focus-within:ring-1 focus-within:ring-blue-400">
              <label htmlFor="description" className="sr-only">
                {t("Description")}
              </label>
              {!formik?.isSubmitting && (
                <textarea
                  wrap="off"
                  rows={2}
                  name="description"
                  id="description"
                  className="block w-full pt-2.5 resize-none border-0 py-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                  placeholder="Write a description..."
                  value={formik?.values?.description}
                  onChange={(e) =>
                    formik.setFieldValue("description", e.target.value)
                  }
                />
              )}

              {/* Spacer element to match the height of the toolbar */}
              <div aria-hidden="true">
                <div className="py-2">
                  <div className="px-2">
                    <TagsView
                      tags={formik.values.tags}
                      onDeleteTag={(idx) => handleDeleteTag(idx, formik)}
                    />{" "}
                  </div>
                  <div className="h-9" />
                </div>
                <div className="h-px" />
                <div className="py-2">
                  <div className="py-px">
                    <div className="h-9" />
                  </div>
                </div>
              </div>
            </div>

            <div className="absolute inset-x-px bottom-0">
              <div className="flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-4 sm:px-3">
                <div className="flex">
                  <div className="">
                    <DocumentUploadSectionV2
                      version={4}
                      module="CASE"
                      module_param={caseItem.id}
                      parentCallback={(data) => UploaderCallback(data, formik)}
                    />
                  </div>
                  <div className="max-h-[20px] ">
                    <DocumentViewer
                      size="small"
                      pathType="quickview"
                      documents={formik?.values?.documents}
                      onChange={(data) => {
                        formik.setFieldValue("documents", data);
                      }}
                    />
                  </div>
                  <div className="ml-2 border-l-2 border-gay-50">
                    <TagSelector formik={formik} />
                  </div>
                </div>
                <div className="flex-shrink-0">
                  <button
                    onClick={formik.submitForm}
                    type="button"
                    className="disabled:bg-gray-200 inline-flex items-center rounded-md bg-blue-400 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                    disabled={formik?.isSubmitting}
                  >
                    {t("Create")}
                  </button>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
}

export default function CaseDialog(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [itemState, setItemState] = useState({
    isLoading: true,
    error: false,
    errors: [],
    caseItem: {},
  });

  const {
    open,
    onClose,
    selected,
    onEditing,
    customer,
    project,
    report,
    contact,
    onRefresh,
    title,
    itemTags,
  } = props;

  const { isLoading, error, errors, caseTypes, caseItem, users } = itemState;

  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  const [serviceAgreementDialogOpen, setServiceAgreementDialogOpen] =
    useState(false);

  const [userOptions, setUserOptions] = useState({
    isLoading: true,
    error: false,
    errors: [],
    data: [],
  });

  const [caseTypeOptions, setCaseTypeOptions] = useState({
    isLoading: true,
    error: false,
    errors: [],
    data: [],
  });

  const rightSideActions = [
    {
      label: "Service Report",
      description: "Create a service report",
      path: "",
      action: () => promptBeforeModuleDialog("report"),
      icon: Cog6ToothIcon,
      iconForeground: "text-teal-700",
      iconBackground: "bg-teal-50",
      completed: caseItem?.report?.id ? true : false,
      show: props.module === "service_report" ? false : true,
    },
    {
      label: "Service Agreement",
      description: "Create a service agreement",
      path: "",
      action: () => promptBeforeModuleDialog("service_agreement"),

      icon: UsersIcon,
      iconForeground: "text-purple-700",
      iconBackground: "bg-purple-50",
      completed: caseItem?.service_agreement?.id ? true : false,
      show: props.module === "service_agreement" ? false : true,
    },
    {
      label: "Project",
      description: "Create a project",
      path: "",
      action: () => promptBeforeModuleDialog("project"),

      icon: BuildingOffice2Icon,
      iconForeground: "text-sky-700",
      iconBackground: "bg-sky-50",
      completed: caseItem?.project?.id ? true : false,
      show: props.module === "project" ? false : true,
    },
    {
      label: "Order",
      description: "Create a order",
      path: "",
      action: () => promptBeforeModuleDialog("order"),
      icon: BanknotesIcon,
      iconForeground: "text-yellow-700",
      iconBackground: "bg-yellow-50",
      completed: caseItem?.order?.id ? true : false,
      show: props.module === "order" ? false : true,
    },
  ];

  async function fetchAndMapTypeOptions() {
    setCaseTypeOptions((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    // Fetch Case types from API?
    return fetchCaseTypes()
      .then((res) => {
        if (res.data) {
          var array = [];
          if (!res.data) return array;
          res?.data?.map((row, i) => {
            const rowParams = {
              value: row.id,
              title: row.title,
              order: i,
            };
            array.push(rowParams);
          });
          setCaseTypeOptions((prevState) => ({
            ...prevState,
            isLoading: false,
            data: array,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        setCaseTypeOptions((prevState) => ({
          ...prevState,
          isLoading: false,
          error: true,
          errors: errors,
        }));
      });

    // Map into an array with ID and title?

    // Return the array
  }

  async function fetchAndMapUsers() {
    setUserOptions((prevState) => ({
      ...prevState,
      isLoading: true,
    }));
    // Fetch Case types from API?
    const controller = {
      filters: [{ name: "role", value: "case" }],
      page: 1,
      query: "",
      sort: "desc",
      sortBy: "id",
      view: "all",
    };

    await fetchUsers(controller)
      .then((res) => {
        if (res.data) {
          var array = [];
          if (!res.data) return array;
          res?.data?.map((row, i) => {
            const rowParams = {
              value: row.id,
              title: row.name,
              order: i,
            };
            array.push(rowParams);
          });
          setUserOptions((prevState) => ({
            ...prevState,
            isLoading: false,
            data: array,
          }));
        }
      })
      .catch((err) => {
        let errors = null;
        setUserOptions((prevState) => ({
          ...prevState,
          isLoading: false,
          error: true,
          errors: errors,
        }));
      });

    // Map into an array with ID and title?

    // Return the array
  }

  const getCase = async () => {
    await fetchCase(selected.id)
      .then((res) => {
        setTimeout(() => {
          setItemState((prevState) => ({
            ...prevState,
            isLoading: false,
            caseItem: res,
          }));
        }, 100);
      })
      .catch((err) => {
        let errors = null;
        if (err?.data?.errors) {
          errors = mapErrors(err?.data?.errors);
        } else {
          errors = ["Something went wrong"];
        }
        setItemState((prevState) => ({
          ...prevState,
          // isLoading: false,
          caseItem: {},
          error: true,
          errors: errors,
        }));
      });
  };

  const createOrUpdateDispatch = (data) => {
    // onEditing(false);
    if (caseItem?.id) {
      const res = dispatch(updateCaseAsync(data));
      return res;
    } else {
      const res = dispatch(createCaseAsync(data));
      return res;
    }
  };

  useEffect(() => {
    setCurrentTab(0);
    fetchAndMapTypeOptions();
    fetchAndMapUsers();
  }, [open]);

  useEffect(() => {
    if (open) {
      setItemState((prevState) => ({
        ...prevState,
        isLoading: true,
      }));
      if (selected?.id) {
        getCase();
      } else {
        setItemState((prevState) => ({
          ...prevState,
          isLoading: false,
          caseItem: {},
          error: false,
          errors: null,
        }));
      }
    } else {
      setItemState((prevState) => ({
        ...prevState,
        // isLoading: false,
        caseItem: {},
        error: false,
        errors: null,
      }));
    }
  }, [open, selected, dispatch]);

  const MODULE = (module) => {
    switch (module) {
      case "contactForm":
        return "henvndelse via kontaktskjema";
      case "customer":
        return "kunde";
      case "case":
        return "denne saken";
      case "report":
        return "rapport";
      case "description":
        return "beskrivelse";
      default:
        return module;
    }
  };

  const ACTION = (action) => {
    switch (action) {
      case "ADDED":
        return "lagt til";
      case "CREATE":
        return "opprettet";
      case "UPDATE":
        return "oppdatert";
      case "ATTACHED":
        return "lagt til";
      case "CHANGED":
        return "endret";
      case "STATUS_UPDATE":
        return "oppdatert status på";
      case "sent_sms_to_customer":
        return "sendt SMS til kunde via";
      case "cancel_report_agreement":
        return "kansellert avtale for";
      case "new_report_agreement":
        return "opprettet ny avtale for";
      default:
        return action;
    }
  };

  const displayModuleContent = (item) => {
    const { module, module_param } = item;
    switch (module) {
      case "case":
        return (
          <Link
            to={"/dashboard/cases/" + item?.id}
            className="hover:text-blue-800 text-blue-500"
          >
            {item?.displayTitle}
          </Link>
        );
      case "customer":
        return (
          <Link
            to={"/dashboard/customers/" + item?.customer?.id}
            className="hover:text-blue-800 text-blue-500"
          >
            {item?.customer?.displayTitle}
          </Link>
        );
      case "project":
        return (
          <Link
            to={"/dashboard/projects/" + item?.project?.id}
            className="hover:text-blue-800 text-blue-500"
          >
            {item?.project?.displayTitle}
          </Link>
        );
      case "service_agreement":
        return (
          <Link
            to={"/dashboard/service/agreements/" + item?.service_agreement?.id}
            className="hover:text-blue-800 text-blue-500"
          >
            {"Service Agreement " + item?.service_agreement?.agreement_number}
          </Link>
        );
      case "order":
        return (
          <Link
            to={"/dashboard/orders/" + item?.order?.id}
            className="hover:text-blue-800 text-blue-500"
          >
            {"Order " + item?.order?.order_number}
          </Link>
        );
      case "report":
        return (
          <Link
            to={"/dashboard/service/reports/" + item?.report?.id}
            className="hover:text-blue-800 text-blue-500"
          >
            {"Report " + item?.report?.report_number}
          </Link>
        );
      default:
        break;
    }
  };

  const tabs = [
    { name: "Detaljer", href: "#", current: true },
    { name: "Annen informasjon", href: "#", current: false },
  ];

  const [currentTab, setCurrentTab] = useState(0);

  const [customerDialogOpen, setCustomerDialogOpen] = useState(false);

  function caseTabs() {
    return (
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id="tabs"
            name="tabs"
            className="block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500"
            defaultValue={tabs[currentTab].name}
          >
            {tabs.map((tab, index) => (
              <option key={tab.name} value={tab.index}>
                {tab.name}
              </option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex" aria-label="Tabs">
              {tabs.map((tab, index) => (
                <a
                  key={tab.name}
                  onClick={() => setCurrentTab(index)}
                  className={TWclassNames(
                    index === currentTab
                      ? "border-blue-500 text-blue-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium"
                  )}
                  aria-current={tab.current ? "page" : undefined}
                >
                  {tab.name}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
    );
  }

  function caseOther() {
    return (
      <div className="inline-block min-w-full py-2 sm:px-6 lg:px-8">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
              >
                Name
              </th>
              <th
                scope="col"
                className="py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
              >
                Value
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {caseItem?.fields?.map((field) => (
              <tr key={field.name}>
                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                  {field.name}
                </td>
                <td className="py-4 px-3 text-sm text-gray-500 min-w-[200px]">
                  <CopyToClipboard>{field.value}</CopyToClipboard>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }

  const handleDescriptionItemChange = (e, formik) => {
    const { name, value } = e.target;
    const items = [...formik.values.items];
    items[0].description = value;
    if (itemTags) {
      items[0].tags = itemTags;
    }
    formik.setFieldValue("items", items);
  };

  const [selectedItem, setSelectedItem] = useState(null);
  const [removeCaseItemPromptOpen, setRemoveCaseItemPromptOpen] =
    useState(false);

  const handleDeleteItem = (id) => {
    setSelectedItem(id);
    setRemoveCaseItemPromptOpen(true);
  };

  const removeCaseItem = () => {
    deleteCaseItem({ case_id: caseItem?.id, id: selectedItem })
      .then((res) => {
        const newCaseItem = {
          ...caseItem,
          items: caseItem?.items?.filter((item) => item.id !== selectedItem),
        };

        setItemState((prevState) => ({
          ...prevState,
          caseItem: newCaseItem,
        }));

        setRemoveCaseItemPromptOpen(false);
      })
      .catch((err) => {
        console.error(err);
        toast.error(t("Could not delete item"));
      });
  };

  // if (itemTags && caseItem?.id === null) {
  //   useEffect(() => {
  //     const tags = itemTags.map((item) => {
  //       return {
  //         module: item.module,
  //         module_param: item.module_param,
  //         object: item.object,
  //       };
  //     });

  //     // add to first item in items
  //     setItemState((prevState) => ({
  //       ...prevState,
  //       caseItem: {
  //         ...prevState.caseItem,
  //         items: [{ description: "", tags: tags }],
  //       },
  //     }));
  //   }, [itemTags]);
  // }

  function caseInputs(formik) {
    return (
      <>
        <div className="flex gap-4 col-span-6 sm:col-span-6">
          <CaseStatus caseItem={caseItem} />
          <CasePriorityStatus priority={caseItem?.priority} />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <PMField
            name="case_number"
            disabled
            type="text"
            label={t("Case Number")}
            {...formik}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <PMField
            disabled
            name="date"
            type="date"
            label={t("Case Date")}
            {...formik}
          />
        </div>

        <div className="col-span-6 sm:col-span-6">
          <PMField name="title" type="text" label={t("Tittel")} {...formik} />
        </div>

        {!props.customer?.id && (
          <div className="col-span-6 sm:col-span-3">
            <CustomerSelector
              selected={caseItem?.customer?.id ? caseItem?.customer : customer}
              searchQuery={caseItemFields?.name}
              fields={caseItemFields}
              {...formik}
            />
          </div>
        )}

        <div className="col-span-6 sm:col-span-3">
          <ContactsSelector
            selected={caseItem?.contact?.id ? caseItem?.contact : contact}
            searchQuery={caseItemFields?.name}
            fields={caseItemFields}
            {...formik}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <PMCombobox
            name="case_type_id"
            label={t("Case Type")}
            options={caseTypeOptions.data}
            {...formik}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <PMCombobox
            name="priority"
            label={t("Priority")}
            options={priorityOptions}
            {...formik}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <PMCombobox
            name="user_assigned_id"
            label={t("User Assigned")}
            options={userOptions.data}
            {...formik}
          />
        </div>

        <div className="col-span-6 sm:col-span-3">
          <PMField
            name="due_date"
            type="date"
            label={t("Forfallsdato")}
            {...formik}
          />
        </div>

        {!caseItem?.id && (
          <div className="col-span-6 sm:col-span-6">
            <label htmlFor="description" className="font-sm text-gray-700">
              {t("Description")}
            </label>
            <textarea
              // disabled={isLoading}
              wrap="off"
              rows={4}
              name="description"
              id="description"
              className="mt-2 block w-full pt-2.5 resize-none border-2 py-0 text-gray-900 border-blue-400 rounded-md shadow-md placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 disabled:bg-gray-400 "
              placeholder="Write a description..."
              value={formik.values.items[0].description}
              onChange={(e) => handleDescriptionItemChange(e, formik)}
              defaultValue={""}
            />
          </div>
        )}
      </>
    );
  }
  function caseItems() {
    return (
      <div className="bg-white shadow sm:rounded-md">
        <ul role="list" className="divide-y divide-gray-200">
          {caseItem?.items?.map((item) => (
            <li key={item.id}>
              <div className="absolute right-10 py-3">
                <button
                  type="button"
                  onClick={() => handleDeleteItem(item.id)}
                  className=""
                >
                  <TrashIcon className="h-5 w-5 text-red-500" />
                </button>
              </div>
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 sm:flex  break-all overflow-hidden sm:items-center sm:justify-between">
                  <div className="">
                    <div className="flex text-sm">
                      <p className="truncate font-medium text-blue-600">
                        {item?.user ? item?.user?.name : "Systemet"}
                      </p>
                      <p className="ml-1 flex-shrink-0 font-normal text-gray-500">
                        {" har "}
                        {ACTION(item?.action)} {MODULE(item?.module)}
                      </p>
                    </div>
                    <div className="mt-2 flex">
                      <div className="flex items-center text-sm text-gray-500">
                        <CalendarIcon
                          className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                          aria-hidden="true"
                        />
                        <p>
                          {t("Added")}{" "}
                          <time dateTime={item?.created_at}>
                            {item?.created_at}
                          </time>
                        </p>
                      </div>
                    </div>
                    {item?.item_change && (
                      <div className="mt-5 py-2">
                        {
                          <ChangedValues
                            module={item?.action}
                            change={item?.item_change}
                          />
                        }
                      </div>
                    )}
                    <div className="mt-5">
                      {displayModuleContent(item)}
                      <NewLineFormatter value={item?.description} />

                      <div className="mt-2">
                        <TagsView tags={item?.tags} />
                      </div>
                    </div>
                    {item?.documents?.length > 0 && (
                      <div className="mt-10">
                        <DocumentViewer
                          size="small"
                          pathType="quickview"
                          documents={item?.documents}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {/* <div className="ml-5 flex-shrink-0">
                    <ChevronRightIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div> */}
              </div>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  const IsEditingListner = () => {
    // const { values } = useFormikContext();
    // useEffect(() => {
    //   onEditing(true);
    // }, [values]);
  };

  const [finishCase, setFinishCase] = useState(false);
  const [finishQuestionPromptOpen, setFinishQuestionPromptOpen] =
    useState(false);
  const [moduleType, setModuleType] = useState(null);

  const onUpdateModuleChange = (type, data) => {
    // ids objects depending on type
    const id_name = {
      report: "report_id",
      project: "project_id",
      service_agreement: "service_agreement_id",
    };

    const payload = {
      id: caseItem.id,
      [id_name[type]]: data?.id,
    };

    // add status: 'FINISH' if report is finished
    if (finishCase) {
      payload.status = "FINISHED";
    }

    dispatch(updateCaseAsync(payload));

    setFinishCase(false);
  };

  const promptBeforeModuleDialog = (type) => {
    setModuleType(type);
    if (!caseItem?.[type]?.id) {
      setFinishQuestionPromptOpen(true);
    } else {
      openModuleDialog(type);
    }
  };

  const handleFinishQuestion = (boolean) => {
    if (boolean) {
      setFinishCase(boolean);
    }
    setFinishQuestionPromptOpen(false);
    openModuleDialog(moduleType);
  };

  const openModuleDialog = (type) => {
    // Open module dialog depending on type
    switch (type) {
      case "report":
        setReportDialogOpen(true);
        break;
      case "service_agreement":
        setServiceAgreementDialogOpen(true);
        break;
      default:
        toast.error("Missing dialog for this module");
        break;
    }
  };

  // Format caseItem fields to find object by name
  const caseItemFields = caseItem?.fields?.reduce((acc, field) => {
    acc[field.name] = field.value;
    return acc;
  }, {});

  function handleNewCaseItems(data) {
    const newCaseItem = {
      ...caseItem,
      items: [data, ...caseItem.items],
    };

    setItemState((prevState) => ({
      ...prevState,
      caseItem: newCaseItem,
    }));
  }

  const [closeCasepromptOpen, setCloseCasepromptOpen] = useState(false);
  const [finishCasepromptOpen, setFinishCasepromptOpen] = useState(false);
  const [removeCasePromptOpen, setRemoveCasePromptOpen] = useState(false);

  const updateAndCloseCase = (formik) => {
    formik.setFieldValue("status", "CLOSED");
    formik.submitForm();
    onClose();
  };

  const updateAndFinishCase = (formik) => {
    formik.setFieldValue("status", "FINISHED");
    formik.submitForm();
    onClose();
  };

  const removeCase = async (id) => {
    dispatchWithToast(dispatch(deleteCaseAsync({ id: id }), null, onClose()));
  };

  const dropdownActions = [
    {
      label: "edit",
      children: [
        {
          label: t("Save and finish"),
          icon: PencilSquareIcon,
          action: () => setFinishCasepromptOpen(true),
        },
        {
          label: t("Save and close"),
          icon: PencilSquareIcon,
          action: () => setCloseCasepromptOpen(true),
        },
        {
          label: t("Delete"),
          icon: TrashIcon,
          action: () => setRemoveCasePromptOpen(true),
        },
      ],
    },
  ];

  const fieldOrCustomerAddressObject = (item) => {
    if (
      item?.customer?.postal_address &&
      item?.customer?.postal_address?.address !== "null"
    ) {
      return item?.customer?.postal_address;
    }

    const address = searchFieldsByName(item, "address")?.value;

    if (address) {
      const address2 = searchFieldsByName(item, "address2")?.value;
      const postal = searchFieldsByName(item, "postal")?.value;
      const place = searchFieldsByName(item, "place")?.value;

      // find fields

      return {
        address: address,
        address2: address2,
        postal: postal,
        place: place,
      };
    }

    return null;
  };

  const currentUser = useSelector((state) => state.session.currentUser);

  return (
    <>
      <PMDialog
        isLoading={isLoading}
        size="7xl"
        open={open}
        onClose={() => {}}
        title={caseItem?.id ? t("Update Case") : t("Create Case")}
      >
        <Formik
          enableReinitialize
          initialValues={{
            id: caseItem?.id,
            customer_id: caseItem?.customer
              ? caseItem?.customer?.id
              : customer?.id
              ? customer?.id
              : null,
            contact_id: caseItem?.contact
              ? caseItem?.contact?.id
              : contact?.id
              ? contact?.id
              : null,
            report_id: caseItem?.report
              ? caseItem?.report?.id
              : report?.id
              ? report?.id
              : null,
            project_id: caseItem?.project
              ? caseItem?.project?.id
              : project?.id
              ? project?.id
              : null,
            case_number: caseItem?.case_number,
            date: caseItem?.date
              ? caseItem?.date
              : new Date().toISOString().substring(0, 10),
            title: caseItem?.title || title,
            description: caseItem?.description,
            case_type_id: caseItem?.case_type?.id,
            module: props?.module,
            module_param: props?.module_param,
            user_responsible_id: caseItem?.user_responsible?.id,
            user_assigned_id: caseItem?.user_assigned?.id || currentUser?.id,
            note: caseItem?.note,
            priority: caseItem?.priority?.toString() || "1",
            due_date:
              caseItem?.due_date || new Date().toISOString().substring(0, 10),

            items: [
              {
                description: null,
              },
            ],

            submit: null,
          }}
          validationSchema={Yup.object({
            case_type_id: Yup.string().required("A case type is required"),
          })}
          onSubmit={(values, helpers) => {
            try {
              dispatchWithToast(
                createOrUpdateDispatch(values),
                (data) => {
                  // if (onRefresh) {
                  //   onRefresh();
                  // }
                  setItemState((prevState) => ({
                    ...prevState,
                    caseItem: data,
                  }));
                },
                values?.id ? onClose() : null
              );
              helpers.setStatus({ success: true });
              helpers.setSubmitting(false);
            } catch (err) {
              console.log("err", err);
              toast.error("Something went wrong...");
              console.error(err);
              helpers.setStatus({ success: false });
              helpers.setErrors({ submit: err.message });
              helpers.setSubmitting(false);
            }
          }}
        >
          {(formik) => (
            <Form>
              <div
                className={TWclassNames(
                  caseItem?.id
                    ? "grid grid-cols-3 gap-6"
                    : "grid grid-cols-2 gap-6"
                )}
              >
                <div className="col-span-3 sm:col-span-2">
                  <div className="grid grid-cols-6 gap-6 mt-10">
                    <div className="col-span-6 sm:col-span-6">
                      <div>{caseTabs()}</div>
                    </div>
                    {currentTab === 0 ? caseInputs(formik) : null}
                    <div className="col-span-6 sm:col-span-6">
                      {currentTab === 1 ? caseOther() : null}
                    </div>
                  </div>
                </div>
                {caseItem?.id && (
                  <div className="col-span-4 sm:col-span-2">
                    <div className=" mt-10">
                      <PMLinkCard actions={rightSideActions} />
                    </div>
                  </div>
                )}

                <div className="col-span-4 sm:col-span-4 align-left left">
                  <GoogleMapsButton
                    address={fieldOrCustomerAddressObject(caseItem)}
                  />
                </div>

                {caseItem?.id && (
                  <div className="col-span-4 sm:col-span-4">
                    <CaseItemUpdate
                      caseItem={caseItem}
                      onChange={(data) => handleNewCaseItems(data)}
                    />
                  </div>
                )}
                <div className="col-span-4">{caseItems()}</div>
              </div>

              <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                <button
                  type="button"
                  className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
                  onClick={onClose}
                >
                  {t("Close")}
                </button>

                <div className="w-full flex justify-end gap-6">
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 sm:text-sm"
                    onClick={formik.submitForm}
                  >
                    {caseItem?.id ? t("Update") : t("Create")}
                  </button>

                  {formik.values.id && (
                    <PMDropdown
                      label={t("Actions")}
                      options={dropdownActions}
                    />
                  )}
                </div>
              </div>

              <IsEditingListner />

              <SimplePrompt
                title="Sikker på at du vill lukke saken?"
                description="Saken lagres og lukkes, men kan åpnes igjen senere."
                onAccept={() => updateAndCloseCase(formik)}
                open={closeCasepromptOpen}
                onClose={() => setCloseCasepromptOpen(false)}
              />

              <SimplePrompt
                title="Sikker på at du vill endre status til ferdig?"
                description="Saken lagres og markeres som ferdig"
                onAccept={() => updateAndFinishCase(formik)}
                open={finishCasepromptOpen}
                onClose={() => setFinishCasepromptOpen(false)}
              />

              <SimplePrompt
                title="Sikker på at du vill slette saken?"
                description="Saken slettes permanent."
                onAccept={() => removeCase(formik.values.id)}
                open={removeCasePromptOpen}
                onClose={() => setRemoveCasePromptOpen(false)}
              />

              <SimplePrompt
                title="Sikker på at du vill slette denne linjen?"
                description="Saken slettes permanent."
                onAccept={() => removeCaseItem(selectedItem)}
                open={removeCaseItemPromptOpen}
                onClose={() => setRemoveCaseItemPromptOpen(false)}
              />
            </Form>
          )}
        </Formik>
      </PMDialog>

      <ServiceReportEditModal
        module="case"
        module_param=""
        customer={caseItem?.customer}
        project={caseItem?.project}
        fields={caseItemFields}
        open={reportDialogOpen}
        onClose={() => setReportDialogOpen(false)}
        selected={caseItem?.report?.id ? caseItem?.report : null}
        onRefresh={(data) => onUpdateModuleChange("report", data)}
      />

      <CustomerDialog
        open={customerDialogOpen}
        onClose={() => setCustomerDialogOpen(false)}
        readOnly={true}
        // selected={{ report.customer }}
        // Only for testing
        selected={caseItem?.customer}
        onRefresh={() => getCase()}
      />

      <ServiceAgreementModal
        open={serviceAgreementDialogOpen}
        onClose={() => {
          setServiceAgreementDialogOpen(false);
        }}
        customer={caseItem?.customer}
        project={caseItem?.project}
        fields={caseItemFields}
        onRefresh={(data) => {
          onUpdateModuleChange("service_agreement", data);
        }}
        selected={
          caseItem?.service_agreement?.id ? caseItem?.service_agreement : null
        }
      />

      <SimplePrompt
        title="Ønsker du å ferdigstille saken etterpå?"
        description=""
        onAccept={() => handleFinishQuestion(true)}
        open={finishQuestionPromptOpen}
        onClose={() => handleFinishQuestion(false)}
      />
    </>
  );
}

CaseDialog.defaultProps = {
  onRefresh: () => {},
};
