import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import axios from "../../../api/axios";
import { ResourceLoading } from "../../utils/ResourceLoading";
import { DocumentViewer } from "../Media/documentViewer";

export default function DocumentsDialog(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { open, onClose, selected, error, errors, module, moduleParam } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [page, setPage] = useState(1);

  const report = selected;

  const [state, setState] = useState({});

  const getDocuments = async () => {
    setIsLoading(true);
    const params = {
      module: module,
      module_param: moduleParam,
      page: page,
      per_page: 20,
    };
    const response = await axios.get(`/documents`, { params });
    setState({
      documents: response.data.documents,
      document_pages: response.data.pages - 1,
      isLoading: false,
    });
    setTimeout(() => {
      setIsLoading(false);
    }, 50);
  };

  useEffect(() => {
    if (open) {
      getDocuments();
    }
  }, [open]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => null}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="w-full sm:max-w-4xl max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900"
                  >
                    Dokumenter & bilder
                  </Dialog.Title>

                  {isLoading ? (
                    <ResourceLoading />
                  ) : (
                    <>
                      <>
                        {state?.documents && (
                          <div className="overflow-hidden py-5 min-h-[300px]">
                            <DocumentViewer
                              size="large"
                              pathType="quickview"
                              documents={state?.documents}
                            />
                          </div>
                        )}
                      </>

                      <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                        <button
                          type="button"
                          className="inline-flex justify-center w-20 rounded-md border border-transparent bg-gray-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                          onClick={onClose}
                        >
                          {t("Close")}{" "}
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
