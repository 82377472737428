import { ExclamationCircleIcon } from "@heroicons/react/24/outline";

export default function NoteField(props) {
  const { submitForm, setFieldValue, errors, touched, values } = props;

  return (
    <div className="flex items-start space-x-4">
      <div className="min-w-0 flex-1">
        <form action="#">
          <div className="border-b border-gray-200 focus-within:border-blue-600">
            <label htmlFor="comment" className="sr-only">
              Add your comment
            </label>
            <textarea
              rows={3}
              name="note"
              id="note"
              className="block w-full resize-none border-0 border-b border-transparent p-0 pb-2 text-gray-900 placeholder:text-gray-400 focus:border-blue-400 focus:ring-0 sm:text-sm sm:leading-6"
              placeholder="Skriv din kommentar..."
              onChange={(e) => setFieldValue("note", e.target.value)}
              value={values["note"]}
            />
          </div>
          {touched["note"] && errors["note"] && (
            <div className="flex mt-1 text-red-700 text-xs">
              <ExclamationCircleIcon className="w-4 text-xs" />
              <span className="ml-2">{errors["note"]}</span>
            </div>
          )}
          <div className="flex justify-between pt-2">
            <div className="flex-shrink-0">
              <button
                onClick={submitForm}
                type="button"
                className="inline-flex items-center border-b-2 border-blue-400 px-3 py-2 text-sm font-semibold text-blue-400 hover:text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
              >
                Legg til kommentar
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
