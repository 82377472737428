import { Query } from "../../utils/query";
import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useEffect, useState, useTransition } from "react";
import { TWclassNames } from "../../utils/Div";
import axios from "../../../api/axios";
import { useSearchParams } from "react-router-dom";
import { updateUrl } from "../../Utils";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { DatePickerModal } from "../../utils/DatePickerModa";

export const ProductsFilter = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const {
    disabled,
    onQueryChange,
    query,
    initialFilters,
    sortByOptions,
    perPageOptions,
    onPerPageChange,
    pageOptions,
    onPageChange,
    onFilterChange,
    defaultSelectedOptions,
    selectedItems,
    actionBar,
    fromToDate,
    onFromToDateChange,
  } = props;

  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  const [filters, setFilters] = useState(initialFilters);
  const [selectedOptions, setSelectedOptions] = useState(
    defaultSelectedOptions
  );

  useEffect(() => {
    try {
      // Get the filter values from the URL parameters
      const filterValues = searchParams.get("filters");

      if (filterValues) {
        const decodedFilterValues = decodeURIComponent(filterValues);
        const selectedOptions = decodedFilterValues
          .split(",")
          .map((filterValue) => {
            const [name, values] = filterValue.split(":");
            return values.split("|").map((value) => ({
              name: name,
              value: value,
            }));
          })
          .flat(); // flatten the array of arrays into a single array
        setSelectedOptions(selectedOptions);
      } else {
        // If there are no filter values in the URL, update the URL with the current selected filters
        updateUrl(selectedOptions);
      }
    } catch (error) {
      console.error("Error decoding filter values:", error);
    }

    if (filters && initialFilters) {
      Promise.all(
        filters
          .filter((filter) => filter.optionsUrl && !filter.options)
          .map((filter) =>
            axios({
              method: "get",
              url: filter?.optionsUrl,
              params: filter?.optionsParams,
            }).then((response) => {
              console.log(response);
              return response;
            })
          )
      ).then((responses) => {
        const initialFiltersWithLoadedOptions = filters.map((filter, index) => {
          if (filter.options) {
            return filter;
          } else {
            const response = responses[index];
            if (response) {
              const data = response?.data?.data || response?.data;
              const options = data?.map((option) => ({
                id: option.id,
                value: option.id,
                name: filter.name ? filter.name : filter.title,
                label: option.name ? option.name : option.title,
                checked: false,
              }));
              return { ...filter, options };
            } else {
              return filter;
            }
          }
        });
        setFilters(initialFiltersWithLoadedOptions);
      });
    }
  }, []);

  useEffect(() => {
    onFilterChange(selectedFilters);
    updateUrl(selectedFilters);
  }, [selectedOptions]);

  const handleFilterChange = (filterId, optionId) => {
    const updatedFilters = filters.map((filter) => {
      if (filter.id === filterId) {
        const updatedOptions = filter.options.map((option) => {
          if (option.id === optionId) {
            return { ...option, checked: !option.checked };
          } else {
            return option;
          }
        });
        return { ...filter, options: updatedOptions };
      } else {
        return filter;
      }
    });

    setFilters(updatedFilters);
    setSelectedOptions((prevSelectedOptions) => {
      const existingSelectedOptionIndex = prevSelectedOptions.findIndex(
        (selectedOption) =>
          selectedOption.name == filterId && selectedOption.value == optionId
      );
      if (existingSelectedOptionIndex !== -1) {
        const newSelectedOptions = [...prevSelectedOptions];
        newSelectedOptions.splice(existingSelectedOptionIndex, 1);
        return newSelectedOptions;
      } else {
        return [...prevSelectedOptions, { name: filterId, value: optionId }];
      }
    });
  };

  const selectedFilters = selectedOptions?.reduce((acc, selectedOption) => {
    const existingFilter = acc.find(
      (filter) => filter.name === selectedOption.name
    );
    if (existingFilter) {
      existingFilter.values.push(selectedOption.value);
    } else {
      const filter = filters?.find(
        (filter) => filter.id === selectedOption.name
      );
      acc.push({
        id: filter?.id,
        name: filter?.id,
        values: [selectedOption?.value],
      });
    }
    return acc;
  }, []);

  const checkIfChecked = (option, section) => {
    const selectedOption = selectedOptions?.find(
      (selectedOption) =>
        selectedOption.value == option.id && selectedOption.name == section.id
    );
    return selectedOption ? true : false;
  };

  const { t } = useTranslation();

  const handlePerPageChange = (option) => {
    // if (pageNumber < 1) return;
    // if (pageNumber > totalPages) return;
    onPerPageChange?.(option);
  };

  const handlePageChange = (pageNumber) => {
    // if (pageNumber < 1) return;
    // if (pageNumber > totalPages) return;
    onPageChange?.(pageNumber);
  };

  const [datePickerModalOpen, setDatePickerModalOpen] = useState(false);
  return (
    <>
      <div className="mt-5">
        <Query disabled={disabled} onChange={onQueryChange} value={query} />
      </div>

      <div className="relative px-5 top-0 flex h-12 items-center space-x-3 bg-blue-400 rounded-xl mb-2 text-white">
        <div className="w-full ">
          {selectedItems.length > 0 ? (
            actionBar
          ) : (
            <section
              aria-labelledby="filter-heading"
              className="border-gray-200"
            >
              <h2 id="filter-heading" className="sr-only">
                Filters
              </h2>

              <div className="flex items-center justify-between">
                <div>
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="group inline-flex justify-center text-sm font-medium text-white hover:text-gray-900">
                        {t("Sort")}
                        <ChevronDownIcon
                          className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-white group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {sortByOptions?.map((option) => (
                            <Menu.Item key={option}>
                              {({ active }) => (
                                <a
                                  href={option.href}
                                  className={TWclassNames(
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm font-medium text-gray-900"
                                  )}
                                >
                                  {option.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  {perPageOptions && (
                    <Menu
                      as="div"
                      className="relative inline-block text-left ml-5"
                    >
                      <div>
                        <Menu.Button className="group inline-flex justify-center text-sm font-medium text-white hover:text-gray-900">
                          {t("Per Page")}
                          <ChevronDownIcon
                            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-white group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            {perPageOptions?.map((option) => (
                              <Menu.Item key={option}>
                                {({ active }) => (
                                  <button
                                    type="button"
                                    onClick={() => handlePerPageChange(option)}
                                    className={TWclassNames(
                                      active ? "bg-gray-100" : "",
                                      "block w-full px-4 py-2 text-sm font-medium text-gray-900 cursor-pointer text-left"
                                    )}
                                  >
                                    {option}
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}

                  {pageOptions && (
                    <Menu
                      as="div"
                      className="relative inline-block text-left ml-5"
                    >
                      <div>
                        <Menu.Button className="group inline-flex justify-center text-sm font-medium text-white hover:text-gray-900">
                          {t("Page")}
                          <ChevronDownIcon
                            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-white group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute overflow-y-scroll max-h-[200px] left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            {pageOptions?.map((option) => (
                              <Menu.Item key={option}>
                                {({ active }) => (
                                  <button
                                    type="button"
                                    onClick={() => handlePageChange(option)}
                                    className={TWclassNames(
                                      active ? "bg-gray-100" : "",
                                      "block w-full px-4 py-2 text-sm font-medium text-gray-900 cursor-pointer text-left"
                                    )}
                                  >
                                    {option}
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  )}

                  {fromToDate && (
                    <div className="relative inline-block text-left ml-5">
                      <div>
                        <button
                          type="button"
                          className="group inline-flex justify-center text-sm font-medium text-white hover:text-gray-900"
                          onClick={() => setDatePickerModalOpen?.(true)}
                        >
                          {t("From / To Date")}
                        </button>
                      </div>
                    </div>
                  )}
                </div>

                <button
                  type="button"
                  className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
                  onClick={() => setMobileFiltersOpen(true)}
                >
                  Filters
                </button>

                <Popover.Group className="hidden sm:flex sm:items-baseline sm:space-x-8">
                  {filters?.map((section, sectionIdx) => (
                    <Popover
                      as="div"
                      key={section.name}
                      id="menu"
                      className="relative inline-block text-left"
                    >
                      <div>
                        <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-white hover:text-gray-900">
                          <span>{section.name}</span>
                          {/* {sectionIdx === 0 ? (
                              <span className="ml-1.5 rounded bg-gray-200 py-0.5 px-1.5 text-xs font-semibold tabular-nums text-gray-700">
                                1
                              </span>
                            ) : null} */}
                          <ChevronDownIcon
                            className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-white group-hover:text-gray-500"
                            aria-hidden="true"
                          />
                        </Popover.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Popover.Panel className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="space-y-4">
                            {section?.options?.map((option, optionIdx) => (
                              <div
                                key={option.value}
                                className="flex items-center"
                              >
                                <input
                                  id={`filter-${section.id}-${optionIdx}`}
                                  name={`${section.id}[]`}
                                  value={option.id}
                                  defaultValue={option.value}
                                  defaultChecked={checkIfChecked(
                                    option,
                                    section
                                  )}
                                  onChange={(e) =>
                                    handleFilterChange(section.id, option.id)
                                  }
                                  type="checkbox"
                                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                />
                                <label
                                  htmlFor={`filter-${section.id}-${optionIdx}`}
                                  className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                                >
                                  {t(option.label)}
                                </label>
                              </div>
                            ))}
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </Popover>
                  ))}
                </Popover.Group>
              </div>
            </section>
          )}
        </div>
      </div>

      {/* Mobile filter dialog */}
      <Transition.Root show={mobileFiltersOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-40 sm:hidden"
          onClose={setMobileFiltersOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 z-40 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-6 shadow-xl">
                <div className="flex items-center justify-between px-4">
                  <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                  <button
                    type="button"
                    className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    onClick={() => setMobileFiltersOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Filters */}
                <form className="mt-4">
                  {filters?.map((section) => (
                    <Disclosure
                      as="div"
                      key={section.name}
                      className="border-t border-gray-200 px-4 py-6"
                    >
                      {({ open }) => (
                        <>
                          <h3 className="-mx-2 -my-3 flow-root">
                            <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400">
                              <span className="font-medium text-gray-900">
                                {section.name}
                              </span>
                              <span className="ml-6 flex items-center">
                                <ChevronDownIcon
                                  className={TWclassNames(
                                    open ? "-rotate-180" : "rotate-0",
                                    "h-5 w-5 transform"
                                  )}
                                  aria-hidden="true"
                                />
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className="space-y-6">
                              {section?.options?.map((option, optionIdx) => (
                                <div
                                  key={option.value}
                                  className="flex items-center"
                                >
                                  <input
                                    id={`filter-mobile-${section.id}-${optionIdx}`}
                                    name={`${section.id}[]`}
                                    defaultValue={option.value}
                                    type="checkbox"
                                    defaultChecked={option.checked}
                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label
                                    htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                    className="ml-3 text-sm text-gray-500"
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <DatePickerModal
        onChange={onFromToDateChange}
        open={datePickerModalOpen}
        onClose={() => setDatePickerModalOpen(false)}
        title={"Velg periode"}
      />
    </>
  );
};

ProductsFilter.defaultProps = {
  initialFilters: [],
  onFilterChange: () => {},
  defaultSelectedOptions: [],
  fromToDate: false,
  onFromToDateChange: () =>
    toast.error("Nothing will happen. Missing function."),
};
